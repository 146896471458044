import React, { useState } from 'react';

function Table({datas}){
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 50;

    const totalRecords = datas.length;
    const totalPages = Math.ceil(totalRecords / recordsPerPage);

    const handleShowModal = (item) => {
      setSelectedItem(item);
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
        setSelectedItem(null);
        setShowModal(false);
    };
    
    // Function to capitalize the first letter of a string
    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    // const tableHeaders = Object.keys(datas[0] || {}).map((header, index) => (
    //     <th key={index}>{header}</th>
    //   ));
    //   const tableHeaders = Object.keys(datas[0] || {}).map((header, index) => (
    //     <th key={index}>{header.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</th>
    //   ));

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = datas.slice(indexOfFirstRecord, indexOfLastRecord);

    const tableHeaders = Object.keys(datas[0] || {}).map((header, index) => (
      <th key={index}>{header.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</th>
    ));

    const tableRows = currentRecords.map((item, index) => (
      <tr key={index}>
        <td>
          <a href="#" onClick={() => handleShowModal(item)}>
            <i className="fa fa-search fa-fw"></i>
          </a>
        </td>
        {Object.values(item).map((value, subIndex) => (
          <td key={subIndex}>{value}</td>
        ))}
        <td className='text-success'>
          <a style={{ cursor: "pointer" }} onClick={() => handleDownload(item)}>
            Download
          </a>
        </td>
      </tr>
    ));


      const handleDownload = (rowData) => {
        const csvContent = convertToCSV([Object.values(rowData)]);
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'data.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
      
      const convertToCSV = (dataArray) => {
        const header = Object.keys(datas[0]).map(key => key.replace(/_/g, ''));
        const csvRows = [header.join(',')];
      
        dataArray.forEach(data => {
          const csvRow = Object.values(data).map(value => `"${value}"`).join(',');
          csvRows.push(csvRow);
        });
      
        return csvRows.join('\n');
      };
    
return (
<>
    
<div id="content" className="app-content py-3">
      <div className="data-management" data-id="table">
         <table className="table table-bordered table-xs w-100 fw-semibold text-nowrap mb-3" id="datatable">
            
            <thead>
               <tr>
                 <th>#</th>
                {tableHeaders}
                <th>Action</th>
               </tr>
            </thead>
            <tbody className="text-body">
            {tableRows}
            </tbody>
            <tfoot>
               <tr>
               <th>#</th>
               {tableHeaders}
                <th className="text-success">Download All</th>
               </tr>
            </tfoot>
            
         </table>
         {/* <div className="pagination">
        {Array.from({ length: Math.ceil(datas.length / recordsPerPage) }, (_, index) => (
          <button key={index} onClick={() => paginate(index + 1)}>{index + 1}</button>
        ))}
    </div> */}
      </div>
      <div className="pagination">
          <button style={{border:"none"}} onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Prev </button>
          <span>{`${currentPage} of ${totalPages}`}</span>
          <button style={{border:"none"}} onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}> Next</button>
        </div>
   </div>
   

   
<div className={`modal fade ${showModal ? 'show' : ''}`} id="modalDetail" style={{ display: showModal ? 'block' : 'none' }} aria-modal="true" role="dialog">
<div className="modal-dialog" style={{maxWidth: "600px"}}>
<div className="modal-content">
<div className="modal-header py-2">
<h5 className="modal-title fs-16px">Data Informations</h5>
<button onClick={handleCloseModal} className="btn-close" data-bs-dismiss="modal"></button>
</div>
<div className="modal-body">


{selectedItem && (
  <div className="row gx-4 align-items-center">
    <div className="col-sm-12 py-1 fs-13px">
      {Object.entries(selectedItem).map(([key, value], index) => (
        <div className="row mb-10px" key={index}>
          <div className="col-4 fw-bold">{capitalizeFirstLetter(key.replace(/_/g, ' '))} :</div>
          <div className="col-8 text-dark">{value}</div>
        </div>
      ))}
    </div>
  </div>
)}

</div>

</div>
</div>
</div>
</>
);
}
export default Table
