import React, { useState } from 'react';
import { DateRangePicker } from 'rsuite';
// import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
// import "./styles.css"
import Navbar from '../components/navbar'
import Filter from '../components/filter'
import DefaultTable from '../components/default_table';




function PostpaidReport(){
return (
<>
<div id="app" className="app app-content-full-height app-sidebar-collapsed">
    <Navbar/>
    <Filter />

   <DefaultTable/>
  
</div>

</>
);
}
export default PostpaidReport
