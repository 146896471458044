import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import { useAuth } from '../auth/AuthContext';
import { Button } from "rsuite";
import { roles } from "../auth/roles";

export default function Navbar(){
    const authContext = useAuth(); // Get authentication context
    const resolvedPath = useResolvedPath();
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    const { role } = authContext;

    const checkRoleAndRender = (allowedRoles, component) => {
        return allowedRoles.includes(role) ? component :"";
    };
    
    const handleLogout = () => {
      authContext.logout();
    };
    return (
    <>
        <div id="header" className="app-header">
            <div className="desktop-toggler">
                <img src="https://bedcspi.smartpowerbilling.com/static/media/bedc_logo.15dbf0f1.jpeg" className="menu-toggler"/>
            </div>
            <div className="mobile-toggler">
                <img src="" className="menu-toggler"/>
            </div>
            <div className="brand">
                <a href="#" className="brand-logo" style={{marginLeft:"0rem"}}>
                <span className="brand-img">
                <span className="brand-img-text text-theme">B</span>
                </span>
                <span className="brand-text">EDC Reporting</span>
                </a>
            </div>
            <div className="menu">
                <div className="menu-item dropdown dropdown-mobile-full">
                    <a href="#" data-bs-toggle="dropdown" data-bs-display="static" className={isActive ? "active menu-link" : "menu-link"}>
                    Postpaid Report
                    <div className="menu-badge bg-theme"></div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end mt-1 w-300px fs-11px pt-1">
                    <h6 className="dropdown-header fs-10px mb-1">Postpaid Report</h6>
                    <div className="dropdown-divider mt-1"></div>

                    {checkRoleAndRender([roles.admin, roles.billingManager,roles.accounts,roles.pospaidOfficer,roles.prepaidOfficer,roles.headprepaid, roles.ICT, roles.headbilling, roles.billing, roles.auditors], (
                    <CustomLink to="/bill-report/" className="d-flex align-items-center py-10px dropdown-item text-wrap fw-semibold">
                    <div className="fs-20px w-20px">
                        <i className="bi bi-grid text-theme"></i>
                            {/* <i className="bi bi-gear text-theme"></i> */}
                        </div>
                        <div className="flex-1 flex-wrap ps-3">
                            <div className="mb-1 text-inverse">BILLING REPORTS</div>
                            <div className="small text-inverse text-opacity-50">TOTAL REPORT 12900</div>
                        </div>
                        <div className="ps-2 fs-16px">
                            <i className="bi bi-chevron-right"></i>
                        </div>
                    </CustomLink>
                    ))}

                    {checkRoleAndRender([roles.admin, roles.billingManager, roles.accounts,roles.auditors,roles.prepaidOfficer,roles.headprepaid,roles.pospaidOfficer,roles.headbilling, roles.billing, roles.ICT], (
                    <CustomLink to="/payment-report/" className="d-flex align-items-center py-10px dropdown-item text-wrap fw-semibold">
                    <div className="fs-20px w-20px">
                            <i className="bi bi-grid text-theme"></i>
                        </div>
                        <div className="flex-1 flex-wrap ps-3">
                            <div className="mb-1 text-inverse">PAYMENT REPORTS</div>
                            <div className="small text-inverse text-opacity-50">TOTAL REPORT 12900</div>
                        </div>
                        <div className="ps-2 fs-16px">
                            <i className="bi bi-chevron-right"></i>
                        </div>
                    </CustomLink>
                   ))}
                    
                    {checkRoleAndRender([roles.admin, roles.billingManager, roles.accounts,roles.auditors,roles.prepaidOfficer,roles.headprepaid,roles.pospaidOfficer,roles.headbilling, roles.billing, roles.ICT, roles.customerCare], (
                    <CustomLink to="/pos-basic-report/" className="d-flex align-items-center py-10px dropdown-item text-wrap fw-semibold">
                    <div className="fs-20px w-20px">
                            <i className="bi bi-grid text-theme"></i>
                        </div>
                        <div className="flex-1 flex-wrap ps-3">
                            <div className="mb-1 text-inverse">CUSTOMER BASIC REPORT</div>
                            <div className="small text-inverse text-opacity-50">TOTAL REPORT 12900</div>
                        </div>
                        <div className="ps-2 fs-16px">
                            <i className="bi bi-chevron-right"></i>
                        </div>
                    </CustomLink>
                    ))}

                    <hr className="my-0"/>
                
                    </div>
                </div>
                <div className="menu-item dropdown dropdown-mobile-full">
                    <a href="#" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
                    Prepaid Report
                    <div className="menu-badge bg-theme"></div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end mt-1 w-300px fs-11px pt-1">
                    <h6 className="dropdown-header fs-10px mb-1">Prepaid Report</h6>
                    <div className="dropdown-divider mt-1"></div>

                    {checkRoleAndRender([roles.admin, roles.billingManager,roles.accounts,roles.prepaidOfficer,roles.pospaidOfficer,roles.headprepaid,roles.headbilling,roles.auditors], (
                    <CustomLink to="/vending-report/" className="d-flex align-items-center py-10px dropdown-item text-wrap fw-semibold">
                        <div className="fs-20px w-20px">
                        <i className="bi bi-grid text-theme"></i>
                            {/* <i className="bi bi-gear text-theme"></i> */}
                        </div>
                        <div className="flex-1 flex-wrap ps-3">
                            <div className="mb-1 text-inverse">VENDING REPORTS</div>
                            <div className="small text-inverse text-opacity-50">TOTAL REPORT 12900</div>
                        </div>
                        <div className="ps-2 fs-16px">
                            <i className="bi bi-chevron-right"></i>
                        </div>
                    </CustomLink>
                    ))}

                    {checkRoleAndRender([roles.admin, roles.billingManager,roles.accounts,roles.prepaidOfficer,roles.pospaidOfficer,roles.headprepaid,roles.auditors, roles.headbilling], (
                    <CustomLink to="/dept-report/" className="d-flex align-items-center py-10px dropdown-item text-wrap fw-semibold">
                        <div className="fs-20px w-20px">
                            <i className="bi bi-grid text-theme"></i>
                        </div>
                        <div className="flex-1 flex-wrap ps-3">
                            <div className="mb-1 text-inverse">DEBT REPORTS</div>
                            <div className="small text-inverse text-opacity-50">TOTAL REPORT 12900</div>
                        </div>
                        <div className="ps-2 fs-16px">
                            <i className="bi bi-chevron-right"></i>
                        </div>
                    </CustomLink>
                    ))}

                    {checkRoleAndRender([roles.admin, roles.billingManager,roles.accounts,roles.prepaidOfficer,roles.pospaidOfficer,roles.headprepaid,roles.auditors, roles.headbilling,roles.customerCare], (
                    <CustomLink to="/pre-basic-report/" className="d-flex align-items-center py-10px dropdown-item text-wrap fw-semibold">
                        <div className="fs-20px w-20px">
                            <i className="bi bi-grid text-theme"></i>
                        </div>
                        <div className="flex-1 flex-wrap ps-3">
                            <div className="mb-1 text-inverse">CUSTOMER BASIC REPORT</div>
                            <div className="small text-inverse text-opacity-50">TOTAL REPORT 12900</div>
                        </div>
                        <div className="ps-2 fs-16px">
                            <i className="bi bi-chevron-right"></i>
                        </div>
                    </CustomLink>
                    ))}
                    <hr className="my-0"/>
                
                    </div>
                </div>

                {/* <div className="menu-item">
                    <a href="" className="menu-link"> 
                    Settlement Report
                    </a>
                
                    </div>
     */}
                <div className="menu-item dropdown dropdown-mobile-full">
                    <a href="#" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
                    <div className="menu-img online" style={{width: "2rem",height: "1.2rem",marginRight: "-0.6rem"}}>
                        {/* <img src="assets/img/user/profile.jpg" alt="Profile" height="60"/> */}
                        <i className="bi bi-person-circle ms-auto text-theme fs-16px my-n1" height="60"></i>
                    </div>
                    <div className="menu-text d-sm-block d-none w-100px"><span className="__cf_email__">{authContext.user}</span></div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1">
                <div className="dropdown-divider"></div>
                    <button className="dropdown-item d-flex align-items-center" onClick={handleLogout}>LOGOUT <i className="bi bi-toggle-off ms-auto text-theme fs-16px my-n1"></i></button >
                    </div>
                </div>
            </div>

            <form className="menu-search" method="POST" name="header_search_form">
                <div className="menu-search-container">
                    <div className="menu-search-icon"><i className="bi bi-search"></i></div>
                    <div className="menu-search-input">
                    <input type="text" className="form-control form-control-lg" placeholder="Search menu..."/>
                    </div>
                    <div className="menu-search-icon">
                    <a href="#"><i className="bi bi-x-lg"></i></a>
                    </div>
                </div>
            </form>
        </div>

        <button className="app-sidebar-mobile-backdrop"  ></button>

    </>
    );
}


function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
  
    return (
<>
    <Link to={to} {...props}>
        {children}
    </Link>
    </>
    )
  }
