
import React, {useEffect,CSSProperties, useState} from "react";
import { DateRangePicker } from 'rsuite';
// import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// import "./styles.css"
import Navbar from '../../components/navbar'
import Filter from '../../components/filter'
import axios from "axios";
import Table from "../../components/table";
import DefaultTable from "../../components/default_table";
import ClipLoader from "react-spinners/ClipLoader";
import Papa from 'papaparse';

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};


function BasicReport(){
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [month, setMonth] = useState('03');
    const [year, setYear] = useState('2023');
    const [district, setDistrict] = useState('');
    const [account, setAccount] = useState('');
    const [billType, setBillType] = useState('account');

    const [accounts, setAccounts] = useState('');
    const [billTypeselect, setBillTypeselect] = useState('account');
    const [useDistrict, setUseDistrict] = useState(true);
    const [useType, setUseType] = useState(false);

    // const [billTypeselect, setBillTypeselect] = useState(initialValue);
    const [showModal, setShowModal] = useState(false);
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("green");
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const [transactionData, setTransactionData] = useState(null);
    const [alltransactionData, setAllTransactionData] = useState(null);
    const [districtData, setDistrictData] = useState(null);
    const [loadings, setLoadings] = useState(false);
    const apiUrl = "https://bedcbilldownload.smartpowerbilling.com/bill_reporting_form_api/"; // Replace with your API endpoint
    const billingUrl = "https://bedcbilldownload.smartpowerbilling.com/all_bill_reporting_api/";
    const districtUrl = "https://bedcbilldownload.smartpowerbilling.com/select_district_api/";


    const handleShowModal = (item) => {
      setSelectedItem(item);
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
        setSelectedItem(null);
        setShowModal(false);
    };

    const fetchData = async () => {
        setLoadings(true);
        try {
            const headers = {
                "Content-Type": "application/json",
            };
            let data = {
                start_date: startDate,
                end_date: endDate,
                district_id: district,
                bill_type: billType,
                use_district: useDistrict.toString(),
                use_type: useType.toString(),
                month: month,
                year: year,
                account: account,
            };
            // let data = {
            //     start_date: "2023-03-03",
            //     end_date: "2023-11-11",
            //     district_id: "36",
            //     bill_type: "credit",
            //     use_district: "true",
            //     use_type: "false",
            //     month: "03",
            //     year: "2023",
            //     account: ""
            // }


    
        //   if (isAuthenticated) {
        //     // Include the token in the headers if the user is authenticated
        //     headers["Authorization"] = `Bearer ${isAuthenticated}`;
        //   }

            console.log(data,"all")
            const response = await axios.post(apiUrl, data, { headers });
            console.log(response.data.data.rec,"thi")
            setTransactionData(response.data.data.rec);
            setLoadings(false);
            NotificationManager.success(`${response.data.message}`, 'Successful', 3000, { className: 'notification-success' });
        } catch (error) {
            console.error("Error fetching transaction data:", error.message);
            setLoadings(false);
            NotificationManager.error(`${error.message}`, 'Error fetching data!', 5000, () => {
                alert('callback');
            });
        }
    };
    const district_Data = async () => {
        setLoading(true);
        try {
            const headers = {
                "Content-Type": "application/json",
            };
    
            const response = await axios.post(districtUrl,{ headers });
            console.log(response.data.data.rec,"thiy")
            setDistrictData(response.data.data.rec);
            setLoading(false);
            NotificationManager.success(`${response.data.message}`, 'Successful', 3000, { className: 'notification-success' });
        } catch (error) {
            console.error("Error fetching transaction data:", error.message);
            setLoading(false);
            NotificationManager.error(`${error.message}`, 'Error fetching data!', 5000, () => {
                alert('callback');
            });
        }
    };
    const Bill_report_Data = async () => {
        setLoading(true);
        try {
            const headers = {
                "Content-Type": "application/json",
            };
            let data = {
                bill_type: billTypeselect,
                // account: accounts,
            }
    
            const response = await axios.post(billingUrl,data, { headers });
            console.log(response.data.data.rec,"thiy")
            setAllTransactionData(response.data.data.rec);
            setLoading(false);
            NotificationManager.success(`${response.data.message}`, 'Successful', 3000, { className: 'notification-success' });
        } catch (error) {
            console.error("Error fetching transaction data:", error.message);
            setLoading(false);
            NotificationManager.error(`${error.message}`, 'Error fetching data!', 5000, () => {
                alert('callback');
            });
        }
    };

    const downloadCSV = () => {
        const csv = Papa.unparse(transactionData);
        const blob = new Blob([csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'data.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleSelectChange = (e) => {
        // Update state first
        setBillTypeselect(e.target.value);
        // Then call the function
        Bill_report_Data();
      };
    useEffect(() => {
        Bill_report_Data();
        district_Data();
    }, []);
    
return (
<>
<div id="app" className="app app-content-full-height app-sidebar-collapsed">
    <Navbar/>
    {/* <Filter/> */}
    <NotificationContainer/>
        
<nav className="navbar" style={{position:"fixed",height: "3.25rem",
    left: "0",
    right: "0",
    zIndex: 1020,
    display: "flex",
    transition:" all .15s ease-in-out"}}>
  <ul className="menu" style={{marginTop:"-1.6%"}}>
        <li>
        <a className="hasDropdown" href="#">Postpaid Basic Reporting </a></li>
      
        <li>
        <a href="#"><input
            type="text"
            placeholder="Search with any name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            /></a>
        </li>
      

        {/* <li>
        <a href="#">

            <select value={billTypeselect} onChange={(e) => handleSelectChange(e)}>
                <option disabled>Select Bill Type</option>
                <option value="new_customer">New Application</option>
                <option value="new_prepaid">Postpaid To Prepaid Migration</option>
                <option value="credit">Customers On Credit</option>
            </select>
        </a>
        </li> */}
        {billTypeselect === "cus_history" || billTypeselect === "cus_ledger"? 
        <li>
        <a href="#"><input
            type="text"
            placeholder="Enter account number"
            value={accounts}
            onChange={(e) => setAccounts(e.target.value)}
            /></a>
        </li>
        : ""}
       
       
    </ul> <ul style={{marginTop:"-1.6%", display:"flex",flexDirection:"row"}}>
    {/* <li style={{listStyleType: "none"}}>
        <a href="#" onClick={() => handleShowModal()} style={{float:"right",marginRight:"20px",border:"none"}}><button>Export Report</button></a>
        </li> */}

        <li style={{listStyleType: "none"}}>
        <a href="#" onClick={() => handleShowModal()} style={{float:"right",marginRight:"20px",border:"none"}}><button>Create Report</button></a>
        </li>
        </ul>
</nav>

<ClipLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <br/>
      {
        transactionData !== null ? (
            <Table datas={transactionData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
            )} />
        ) : (
            <DefaultTable />
        )
    }


 {/* <Table datas={alltransactionData}/>  */}

<div className={`modal fade ${showModal ? 'show' : ''}`} id="modalDetail" style={{ display: showModal ? 'block' : 'none' }} aria-modal="true" role="dialog">
<div className="modal-dialog" style={{maxWidth: "600px"}}>
<div className="modal-content">
<div className="modal-header py-2">
<h5 className="modal-title fs-16px">Report Generate</h5>
<button onClick={handleCloseModal} className="btn-close" data-bs-dismiss="modal"></button>
</div>
<div className="modal-body">

<div className="row gx-4 align-items-center">
<div className="col-sm-6 mb-3 mb-sm-0">


{billType === "new_customer" ? 
<div className="row mb-10px">
<div className="col-4 fw-bold">Start Date:</div>
<div className="col-8 text-white">
<input type="date" className="form-control form-control-sm" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
    </div>
</div>
:
""
}


{/* <div className="row mb-10px">
<div className="col-4 fw-bold">Month:</div>
<div className="col-8 text-white"><input type="month" className="form-control form-control-sm" value={month} onChange={(e) => setMonth(e.target.value)}/></div>
</div> */}

{billType === "cus_history" || billType === "cus_ledger" ?
<div className="row mb-10px">
<div className="col-4 fw-bold">Account no:</div>
<div className="col-8 text-white">
<input type="text" className="form-control form-control-sm" value={account} onChange={(e) => setAccount(e.target.value)} />
</div>
</div>
:
""
}
<div className="row mb-10px">
<div className="col-4 fw-bold">Bill Type:</div>
<div className="col-8 text-white">
<select style={{ width: "174px" }} className="form-control form-control-sm" value={billType} onChange={(e) => setBillType(e.target.value)}>
                <option>...Select Bill Type...</option>
                <option value="cus_ledger">Customer Ledger</option>
                <option value="cus_history">Postpaid History</option>
                <option value="new_customer">New Application</option>
                <option value="new_prepaid">Postpaid To Prepaid Migration</option>
                <option value="credit">Customers On Credit</option>
                <option value="customers">Customers Dump</option>
            </select>
</div>
</div>


{/* </div> */}

<br></br><br></br>
</div>
<div className="col-sm-6 py-1 fs-13px">

{billType === "new_customer" ? 
<div className="row mb-10px">
<div className="col-4 fw-bold">End date:</div>
<div className="col-8 text-white">
<input type="date" className="form-control form-control-sm " value={endDate} onChange={(e) => setEndDate(e.target.value)} />
    </div>
</div>
:
""
}
{/* <div className="row mb-10px">
<div className="col-4 fw-bold">Year:</div>
<div className="col-8 text-white">
<input type="month" className="form-control form-control-sm" value={year} onChange={(e) => setYear(e.target.value)} />
    </div>
</div> */}
{billType === "customers" ?
<div className="row mb-10px">
<div className="col-4 fw-bold">District:</div>
<div className="col-8 text-white"> 
{/* <select style={{ width: "120px" }} className="form-control form-control-sm" value={district} onChange={(e) => setDistrict(e.target.value)}>
       {districtData !== null ? districtData.map((data, index) => 
        <option key={index}>{option}</option>
        <option value={data.business_unit_id}>{data.business_unit}</option>
       ) :
        <option>no data available</option>
       } 
    </select> */}
<select
  style={{ width: "120px" }}
  className="form-control form-control-sm"
  value={district}
  onChange={(e) => setDistrict(e.target.value)}
>
  {districtData !== null ? (
    districtData.map((data, index) => (
      <option key={index} value={data.business_unit_id}>
        {data.business_unit}
      </option>
    ))
  ) : (
    <option>no data available</option>
  )}
</select>
    </div>
</div>
:
""
}

{billType === "customers" ?
<div className="row">
<div className="col-4 fw-bold">Use district ?:</div>
<div className="col-8 text-white">
<input type="checkbox" className="form-control-sm" checked={useDistrict} onChange={() => setUseDistrict(!useDistrict)} />
    </div>
</div>
:
""
}

{billType === "customers" ?
<div className="row">
<div className="col-4 fw-bold">Use Type ?:</div>
<div className="col-8 text-white">
<input type="checkbox" className="form-control-sm" checked={useType} onChange={() => setUseType(!useType)} />
    </div>
</div>
:
""
}

</div>
</div>
</div>
<div className="modal-footer">
{transactionData !== null ? (
  <button className="btn btn-outline-theme" onClick={downloadCSV}>
    Download & Report
  </button>
) : (
  ''
)}


<a href="#" onClick={handleCloseModal} className="btn btn-outline-default w-100px">Cancel</a>

{loadings === true ?

<button className="btn btn-outline-theme"><ClipLoader
    color={color}
    loading={loadings}
    cssOverride={override}
    size={25}
    aria-label="Loading Spinner"
    data-testid="loader"
  /></button>
:<button onClick={fetchData} className="btn btn-outline-theme">Generat &amp; Report</button>}

</div>
</div>
</div>
</div>

</div>

</>
);
}
export default BasicReport

