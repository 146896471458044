import React, { useEffect, CSSProperties, useState } from "react";
import { DateRangePicker } from 'rsuite';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Papa from 'papaparse';
import { useNavigate } from "react-router-dom";
import { useAuth } from './AuthContext';

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function Login() {
  const [loading, setLoading] = useState(false);
  const { setAuthData } = useAuth();
  const navigate = useNavigate();

  // State for your form fields
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      console.log('Scroll event triggered');
      if (document.body) {
        console.log('Document body exists');
        console.log('Document body classList:', document.body.classList);
      }
    };

    document.onscroll = handleScroll;

    return () => {
      document.onscroll = null;
    };
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('https://bedc.spiapi.smartpowerbilling.com/signin/', {
        login: email,
        password: password,
        table: "res_users",
      });

      if (response.data.status === true) {
        const user = response.data.data.user.name;
        const token = response.data.data.token;
        const role = response.data.data.user.role_ids[0]
        setAuthData(user, token, role);

        // console.log(role, "this his role")
        window.location.href = "/bill-report/"
        // navigate('/bill-report/');
      } else {
        NotificationManager.error('Authentication failed', 'Error');
      }
    } catch (error) {
      NotificationManager.error('Login failed', 'Error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div id="app" className="app app-content-full-height app-sidebar-collapsed" style={{paddingTop:0}}>
        <NotificationContainer />
        <div className="login">
          <div className="login-content">
            <form onSubmit={handleLogin} name="login_form">
              <h1 className="text-center">Sign In</h1>
              <div className="text-inverse text-opacity-50 text-center mb-4">
                For your protection, please verify your identity.
              </div>
              <div className="mb-3">
                <label className="form-label">Email Address <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg bg-inverse bg-opacity-5"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email Address"
                />
              </div>
              <div className="mb-3">
                <div className="d-flex">
                  <label className="form-label">Password <span className="text-danger">*</span></label>
                  {/* <a href="#" className="ms-auto text-inverse text-decoration-none text-opacity-50">Forgot password?</a> */}
                </div>
                <input
                  type="password"
                  className="form-control form-control-lg bg-inverse bg-opacity-5"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value id="customCheck1" />
                  <label className="form-check-label" htmlFor="customCheck1">Remember me</label>
                </div>
              </div>
              <button type="submit" className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3" disabled={loading}>
                {loading ? <ClipLoader size={20} css={override} /> : 'Sign In'}
              </button>
              {/* <div className="text-center text-inverse text-opacity-50">
                Don't have an account yet? <a href="page_register.html">Sign up</a>.
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
