import React, {useState} from "react";
function Filter(){
        
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
  
    const handleShowModal = (item) => {
      setSelectedItem(item);
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
        setSelectedItem(null);
        setShowModal(false);
    };
return (
<>
    
<nav className="navbar">
  <ul className="menu" style={{marginTop:"-1.6%"}}>
        <li>
        <a className="hasDropdown" href="#">Electronics </a></li>
      
        <li>
        <a href="#"><input type="date" placeholder="slect date"/></a>
        </li>
        <li>
        <a href="#"><input type="date"/></a>
        </li>
        <li>
        <a href="#"><input type="date"/></a>
        </li>
       
    </ul> <ul style={{marginTop:"-1.6%"}}><li style={{listStyleType: "none"}}>
        <a href="#" onClick={() => handleShowModal()} style={{float:"right",marginRight:"20px",border:"none"}}><button>Create Report</button></a>
        </li></ul>
</nav>



<div className={`modal fade ${showModal ? 'show' : ''}`} id="modalDetail" style={{ display: showModal ? 'block' : 'none' }} aria-modal="true" role="dialog">
<div className="modal-dialog" style={{maxWidth: "600px"}}>
<div className="modal-content">
<div className="modal-header py-2">
<h5 className="modal-title fs-16px">Report Generat</h5>
<button onClick={handleCloseModal} className="btn-close" data-bs-dismiss="modal"></button>
</div>
<div className="modal-body">

<div className="row gx-4 align-items-center">
<div className="col-sm-6 mb-3 mb-sm-0">

<div className="row mb-10px">
<div className="col-4 fw-bold">Start Date:</div>
<div className="col-8 text-white"><input type="date" className="form-control form-control-sm w-100px"/></div>
</div>
<div className="row mb-10px">
<div className="col-4 fw-bold">Month:</div>
<div className="col-8 text-white"><input type="month" className="form-control form-control-sm w-100px"/></div>
</div>
<div className="row mb-10px">
<div className="col-4 fw-bold">Account no:</div>
<div className="col-8 text-white">
<input type="text" className="form-control form-control-sm w-100px"/>
</div>
</div>



{/* </div> */}







</div>
<div className="col-sm-6 py-1 fs-13px">
<div className="row mb-10px">
<div className="col-4 fw-bold">End date:</div>
<div className="col-8 text-white"><input type="date" className="form-control form-control-sm w-100px"/></div>
</div>
<div className="row mb-10px">
<div className="col-4 fw-bold">Year:</div>
<div className="col-8 text-white"><input type="month" className="form-control form-control-sm w-100px"/></div>
</div>
<div className="row mb-10px">
<div className="col-4 fw-bold">District:</div>
<div className="col-8 text-white"> 
<select style={{width:"120px"}}>
        <option>...........</option>
    </select></div>
</div>


<div className="row">
<div className="col-4 fw-bold">Use distric ?:</div>
<div className="col-8 text-white"><input type="text" className="form-control form-control-sm w-100px"/></div>
</div>
</div>
</div>
</div>
<div className="modal-footer">
<a href="#" onClick={handleCloseModal} className="btn btn-outline-default w-100px">Cancel</a>
<button type="submit" className="btn btn-outline-theme">Generat &amp; Report</button>
</div>
</div>
</div>
</div>
</>
);
}
export default Filter
