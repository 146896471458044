import React, { useState } from 'react';



const TableRow = ({ id, accountNumber, billType, status, startDate, endDate, dueDate, time, location, downloadLink, onShowModal }) => (
    <tr key={id}>
      <td><a href="#" onClick={() => onShowModal()}><i className="fa fa-search fa-fw"></i></a></td>
      <td>{id}.</td>
      <td>{accountNumber}</td>
      <td>{billType}</td>
      <td className={`text-${status === 'success' ? 'success' : 'danger'}`}>
        <i className={`bi-${status === 'success' ? 'check-circle-fill' : 'exclamation-circle-fill'}`}></i>
      </td>
      <td>{startDate}</td>
      <td>{endDate}</td>
      <td>{dueDate}</td>
      <td>{time}</td>
      <td className={`text-${location === 'UGBOWO' ? 'success' : 'danger'}`}>{location}</td>
      <td className={`text-${status === 'success' ? 'success' : 'danger'}`}>
        <i className={`bi-${status === 'success' ? 'check-circle-fill' : 'exclamation-circle-fill'}`}></i>
      </td>
      <td>{'..'}</td>
      <td className={`text-${status === 'success' ? 'success' : 'danger'}`}>{downloadLink}</td>
    </tr>
  );
  
  const data = [];
  
  // Generating 30 rows with random data
  // for (let i = 1; i <= 30; i++) {
  //   const accountNumber = Math.floor(Math.random() * 100000000000).toString();
  //   const billTypeList = ['Payments', 'Adjustment Report', 'Bill Summary', 'Bill Detailed Dump'];
  //   const locationList = ['UGBOWO', 'PTI', 'AUCHI', 'KOKA', 'OBIARUKU', 'ONDO', 'ETETE', 'SAPELE', 'OBIARUKU'];
  //   const randomBillType = billTypeList[Math.floor(Math.random() * billTypeList.length)];
  //   const randomLocation = locationList[Math.floor(Math.random() * locationList.length)];
  
  //   data.push({
  //     id: i,
  //     accountNumber,
  //     billType: randomBillType,
  //     status: Math.random() < 0.5 ? 'success' : 'danger',
  //     startDate: '09/01/2023',
  //     endDate: '09/01/2023',
  //     dueDate: '03/22/2023',
  //     time: '15:09:57',
  //     location: randomLocation,
  //     downloadLink: 'Download',
  //   });
  // }
function DefaultTable(){
    
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
  
    const handleShowModal = (item) => {
      setSelectedItem(item);
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
        setSelectedItem(null);
        setShowModal(false);
    };
return (
<>
    
<div id="content" className="app-content py-3">
      <div className="data-management d-none" data-id="table">
         <table className="table table-bordered table-xs w-100 fw-semibold text-nowrap mb-3" id="datatable">
            <thead>
               <tr>
                 <th>.</th>
                  <th>No.</th>
                  <th>SmartPower Account</th>
                  <th>Report Type</th>
                  <th>Use District?</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Created Date</th>
                  <th>Created Time</th>
                  <th>District</th>
                  <th>Use Bill Type</th>
                  <th>Aging Payment</th>
                 
                  <th>Action</th>
               </tr>
            </thead>
            <tbody className="text-body">
            {/* {data.map((item) => (
                <TableRow key={item.id} {...item} onShowModal={() => handleShowModal(item)} />
            ))} */}
            </tbody>
            <tfoot>
               <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>Select Date</th>
                  <th>Select District</th>
                  <th>Select use type</th>
                  <th>Select Aging</th>
                  <th className="text-success">Download All</th>
               </tr>
            </tfoot>
         </table>
      </div>
   </div>


   
<div className={`modal fade ${showModal ? 'show' : ''}`} id="modalDetail" style={{ display: showModal ? 'block' : 'none' }} aria-modal="true" role="dialog">
<div className="modal-dialog" style={{maxWidth: "600px"}}>
<div className="modal-content">
<div className="modal-header py-2">
<h5 className="modal-title fs-16px">Product Information</h5>
<button onClick={handleCloseModal} className="btn-close" data-bs-dismiss="modal"></button>
</div>
<div className="modal-body">
{selectedItem && (
<div className="row gx-4 align-items-center">
{/* <div className="col-sm-5 mb-3 mb-sm-0">
<div className="card">
<div className="card-body p-2">
<img src="assets/img/product/product-16.jpg" className="mw-100 d-block" alt=""/>
</div>
<div className="card-arrow">
<div className="card-arrow-top-left"></div>
<div className="card-arrow-top-right"></div>
<div className="card-arrow-bottom-left"></div>
<div className="card-arrow-bottom-right"></div>
</div>
</div>
</div> */}
<div className="col-sm-7 py-1 fs-13px">
<div className="row mb-10px">
<div className="col-4 fw-bold">Model:</div>
<div className="col-8 text-white">{selectedItem.billType}</div>
</div>
<div className="row mb-10px">
<div className="col-4 fw-bold">Colour:</div>
<div className="col-8 text-white">Space Black</div>
</div>
<div className="row mb-10px">
<div className="col-4 fw-bold">Storage:</div>
<div className="col-8 text-white">256gb</div>
</div>
<div className="row mb-10px">
<div className="col-4 fw-bold">Size:</div>
<div className="col-8 text-white">147 x 72 x 7.8mm</div>
</div>
<div className="row mb-10px">
<div className="col-4 fw-bold">Category:</div>
<div className="col-8 text-white"><span className="badge bg-theme text-theme-color py-1 fs-10px my-n1 fw-bold">PHONE</span></div>
</div>
<div className="row mb-10px">
<div className="col-4 fw-bold">Price:</div>
<div className="col-8 text-white">$1,999</div>
</div>
<div className="row mb-10px">
<div className="col-4 fw-bold">Cost:</div>
<div className="col-8 text-white">$1,899</div>
</div>
<div className="row mb-10px">
<div className="col-4 fw-bold">Profit:</div>
<div className="col-8 text-success">$200</div>
</div>
<div className="row">
<div className="col-4 fw-bold">Stock:</div>
<div className="col-8 text-white"><input type="text" className="form-control form-control-sm w-100px" value="20"/></div>
</div>
</div>
</div>
 )}
</div>
{/* <div className="modal-footer">
<a href="#" onClick={handleCloseModal} className="btn btn-outline-default w-100px">Cancel</a>
<button type="submit" className="btn btn-outline-theme">Save &amp; Publish</button>
</div> */}
</div>
</div>
</div>
</>
);
}
export default DefaultTable
