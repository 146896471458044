// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [role, setUserRole] = useState(null);

  useEffect(() => {
    // Retrieve user, token, and role from localStorage on component mount
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('token');
    const storedRole = localStorage.getItem('role');

    if (storedUser && storedToken && storedRole) {
      setUser(JSON.parse(storedUser));
      setToken(storedToken);
      setUserRole(storedRole);
    }
  }, []);

  const setAuthData = (userData, authToken, authRole) => {
    setUser(userData);
    setToken(authToken);
    setUserRole(authRole);

    // Save user, token, and role to localStorage
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('token', authToken);
    localStorage.setItem('role', authRole);
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    setUserRole(null);

    // Remove user, token, and role from localStorage on logout
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    window.location.href = "/";
    
  };

  return (
    <AuthContext.Provider value={{ user, token, role, setAuthData, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
