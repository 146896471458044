export const roles = {
    admin: "admin",
    auditors: "auditors",
    channel: "channel",
    // accountant: "accountant",
    metering: "metering",
    customerCare: "customer service",
    vendor: "vendor",
    haudit: "head audit",
    billing: "billing",
    feeder: "feeder",
    headbilling: "head billing",
    officer: "officer",
    operations: "operations",
    supervisor: "supervisor",
    headacct: "head accounts",
    accounts: "ACCOUNTS".toLowerCase(),
    pospaidOfficer:"postpaid officer".toLowerCase(),
    billingManager: "BILLING MANAGERS".toLowerCase(),
    headprepaid: "HEAD PREPAID".toLowerCase(),
    prepaidOfficer: "PREPAID OFFICERS".toLowerCase(),
    ICT: "ICT".toLowerCase(),
    test: "test",
    guest: "guest",
    common: "Prepaid Common User",
    commonuser: "prepaid common user",
  };
  
  