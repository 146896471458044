import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PostpaidReport from './report/postpaid_report';
import BasicReport from './report/postpaid_report/pages/basic_report.js';
import BillReport from './report/postpaid_report/pages/billing_report.js';
import PaymentReport from './report/postpaid_report/pages/payment_report.js';
import PreBasicReport from './report/prepaid_report/pages/basic_report.js';
import DeptReport from './report/prepaid_report/pages/dept_report.js';
import VendReport from './report/prepaid_report/pages/vending_report.js';
import Navbar from './report/components/navbar.js';
import Login from './report/auth/login.js';
import { AuthProvider, useAuth } from './report/auth/AuthContext';
import { roles } from "./report/auth/roles.js";

const App = () => {
  const authContext = useAuth();
  // if (authContext.token === null && window.location.pathname !== '/' && window.location.pathname !== '/login') {
  //   // Redirect to login if the user is not authenticated and not on '/' or '/login'
  //   return (
  //     <Router>
  //       <Routes>
  //         <Route path="/" element={<Navigate to="/login" />} />
  //         <Route path="/login" element={<Login />} />
  //       </Routes>
  //     </Router>
  //   );
  // }

  const isUserAuthorized = (allowedRoles) => allowedRoles.includes(authContext.role);

  // const { role: userRole } = authContext;
  
  // const isUserAuthorized = (allowedRoles) => allowedRoles.includes(userRole);
  return (
    <AuthProvider>
    <Router>
      <>
      <Routes>  
                
                <Route path="/login/" element={
                      <Login />
                  }
                />
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/postpaid-report/" element={
                    isUserAuthorized([roles.admin, roles.billingManager,roles.headprepaid,roles.pospaidOfficer,roles.prepaidOfficer,roles.accounts, roles.ICT, roles.headbilling, roles.billing]) ? (
                      <PostpaidReport />
                    ) : (
                      ""
                    )
                  }
                />
                <Route
                  path="/bill-report/"
                  element={
                    isUserAuthorized([roles.admin, roles.billingManager,roles.headprepaid,roles.prepaidOfficer,roles.pospaidOfficer,roles.accounts, roles.ICT, roles.headbilling, roles.billing, roles.auditors]) ? (
                      <BillReport />
                    ) : (
                      // window.location.href = "/"
                      ""
                    )
                  }
                />

                <Route
                  path="/payment-report/"
                  element={
                    isUserAuthorized([roles.admin, roles.billingManager,roles.pospaidOfficer,roles.headprepaid,roles.prepaidOfficer, roles.accounts,roles.auditors,roles.headbilling, roles.billing, roles.ICT]) ? (
                      <PaymentReport />
                    ) : (
                      ""
                    )
                  }
                />
                <Route
                  path="/pos-basic-report/"
                  element={
                    isUserAuthorized([roles.admin, roles.billingManager, roles.pospaidOfficer,roles.headprepaid,roles.prepaidOfficer, roles.accounts,roles.auditors,roles.headbilling, roles.billing, roles.ICT, roles.customerCare]) ? (
                      <BasicReport />
                    ) : (
                      ""
                    )
                  }
                />
                <Route
                  path="/vending-report/*"
                  element={
                    isUserAuthorized([roles.admin, roles.billingManager,roles.pospaidOfficer,roles.headprepaid,roles.accounts,roles.prepaidOfficer,roles.headprepaid,roles.headbilling,roles.auditors]) ? (
                      <VendReport />
                    ) : (
                      ""
                    )
                  }
                />
                <Route
                  path="/dept-report/*"
                  element={
                    isUserAuthorized([roles.admin, roles.billingManager,roles.pospaidOfficer,roles.headprepaid,roles.accounts,roles.prepaidOfficer,roles.headprepaid,roles.auditors, roles.headbilling]) ? (
                      <DeptReport />
                    ) : (
                      ""
                    )
                  }
                />
                <Route
                  path="/pre-basic-report/*"
                  element={
                    isUserAuthorized([roles.admin, roles.billingManager,roles.pospaidOfficer,roles.headprepaid,roles.accounts,roles.prepaidOfficer,roles.headprepaid,roles.auditors, roles.headbilling,roles.customerCare]) ? (
                      <PreBasicReport />
                    ) : (
                      ""
                    )
                  }
                />
      </Routes>
      </>
    </Router>
    </AuthProvider>
  );
};

export default App;
